import moment from "moment";

import { ReactElement } from "react";

import { Box, Chip, Theme, Typography } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faCircleExclamation, faFlagCheckered, faComments, faFile, faFlaskVial, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { ILog, IPlan } from "./Fetchings";
import { Link } from "react-router-dom";

const validator = require('password-validator');

export const DAYS_OF_MONTH_ARRAY = [...Array(Math.abs(moment().diff(moment().subtract(1, "month"), "day"))).fill(0).map((e, i) => {
    const date = moment().subtract(i, "day");
    if (date.date() === 1) return date.format("MMMM Do");
    else return date.format("Do");
})].reverse();
export const MONTH_ARRAY = new Array(12).fill(0).map((e, i) => moment().subtract(i, "month").format("MMM")).reverse();

export const randomInt = (min: number, max: number) => {
    return Math.floor(min + Math.random() * (max - min));
}

export const PasswordValidator = new validator();
PasswordValidator.is().min(8).has().uppercase().has().lowercase().has().symbols().has().digits(2).has().not().spaces();

export const planStatus = (plan: IPlan, theme: Theme): {
    name: string;
    icon: ReactElement;
} => {
    const today = moment().hour(0).minute(0).second(0).millisecond(0).utc(true);

    if (plan.endOn === undefined || plan.startOn === undefined || today.isSameOrAfter(moment(plan.endOn)) || today.isBefore(moment(plan.startOn))) return {
        name: "Inactive Plan",
        icon: <FontAwesomeIcon icon={faFlagCheckered} color={theme.palette.info.main} />
    }

    switch (plan.status) {
        case "valid": return {
            name: "Active Plan",
            icon: <FontAwesomeIcon icon={faThumbsUp} color={theme.palette.success.main} />
        }
        case "invalid": return {
            name: "Inactive Plan",
            icon: <FontAwesomeIcon icon={faFlagCheckered} color={theme.palette.info.main} />
        }
        default: return {
            name: "Invalid Plan",
            icon: <FontAwesomeIcon icon={faCircleExclamation} color={theme.palette.error.main} />
        }
    }
}

export const invoiceStatus = (id: "success" | "rejected" | "withdrawn"): {
    render: ReactElement;
} => {
    switch (id) {
        case "success": return {
            render: <Chip label="Paid" color="success" />
        }
        case "rejected": return {
            render: <Chip label="Rejected" color="error" />
        }
        case "withdrawn": return {
            render: <Chip label="Withdrawn" color="error" />
        }
        default: return {
            render: <Chip label="Unknown" color="error" />
        }
    }
}

export const buildData = (log: ILog) => {
    switch (log.type) {
        case "chatbot": return {
            type: <Box><FontAwesomeIcon icon={faComments} />{" Chatbot"}</Box>,
        };
        case "workitem": return {
            type: <Box><FontAwesomeIcon icon={faFile} />{" Workitem"}</Box>,
        };
        case "linked-item": return {
            type: <Box><FontAwesomeIcon icon={faFlaskVial} />{" Linked Item"}</Box>,
        };
        case "doc-analysis": return {
            type: <Box><FontAwesomeIcon icon={faFilePdf} />{" Doc Analysis"}</Box>,
        };
        default: return { type: <Box />, details: "" };
    }
}

export const planTypesText = (plan: IPlan) => {
    if (plan.types.trial) return `Trial`;

    const ret = [];
    if (plan.types.small > 0) ret.push(`${plan.types.small} Small`);
    if (plan.types.medium > 0) ret.push(`${plan.types.medium} Medium`);
    if (plan.types.large > 0) ret.push(`${plan.types.large} Large`);
    return ret.join(", ");
}
export const planPeriodText = (plan: IPlan) => {
    const today = moment().hour(0).minute(0).second(0).millisecond(0).utc(true);

    if (plan.endOn === undefined || plan.startOn === undefined) return <Typography>
        Your plan has not been confirmed/started yet
    </Typography>;

    if (today.isSameOrAfter(moment(plan.endOn))) return <Typography>
        Your plan has expired, do you wish to renew? <Link to="mailto:gregor.benitz@bct-technology.com?cc=lucas.romier@bct-technology.com,saskia.nies@bct-technology.com" target="_blank">Contact us!</Link>
    </Typography>;

    if (today.isBefore(moment(plan.startOn))) return <Typography>
        Your plan will start {today.to(moment(plan.startOn))}.
    </Typography>;

    return <Typography>
        Your plan will expire on {moment(plan.endOn).format("MMMM DD, YYYY")} but may be renewed beforehand.
    </Typography>;
}

export const planTypesTextMinimal = (plan: IPlan) => {
    if (plan.types.trial) return `Trial`;

    const ret = [];
    if (plan.types.small > 0) ret.push(`${plan.types.small} S`);
    if (plan.types.medium > 0) ret.push(`${plan.types.medium} M`);
    if (plan.types.large > 0) ret.push(`${plan.types.large} L`);
    return ret.join(", ");
}

export const accessKeyChange = (plan: IPlan) => {
    if (plan.status !== "valid") return false;

    const today = moment().hour(0).minute(0).second(0).millisecond(0).utc(true);
    if (today.isBefore(moment(plan.startOn))) return false;
    if (today.isAfter(moment(plan.endOn))) return false;

    return true;
}