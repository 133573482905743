import { createTheme } from "@mui/material"

import { InternalRoute } from "./react-app-env";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightToBracket, faChartColumn, faRectangleList, faUserGroup, faUsersViewfinder, faRightFromBracket, faUserPlus, faKey, faIdCard, faSection, faFileContract, faInfo, faCookie, faFileCode, faFileDownload, faSatelliteDish } from "@fortawesome/free-solid-svg-icons";
import { CookieConsentConfig } from "vanilla-cookieconsent";

export const DARK_THEME = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#FF7C66',
            contrastText: "#FFF"
        },
        secondary: {
            main: '#FFDED9'
        }
    },
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
});

export const LIGHT_THEME = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#FF7C66',
            contrastText: "#FFF"
        },
        secondary: {
            main: '#FFDED9'
        }
    },
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
});

export const ROUTES: InternalRoute[] = [
    {
        id: "/",
        name: "AI Optimizer",
        icon: <FontAwesomeIcon icon={faInfo} />,
        type: "index"
    },

    {
        id: "/dashboard",
        name: "Dashboard",
        icon: <FontAwesomeIcon icon={faChartColumn} />,
        type: "signed-in"
    },
    {
        id: "/account",
        name: "Account",
        icon: <FontAwesomeIcon icon={faRectangleList} />,
        type: "signed-in"
    },
    {
        id: "/downloads",
        name: "Downloads",
        icon: <FontAwesomeIcon icon={faFileDownload} />,
        type: "signed-in"
    },

    {
        id: "/admin/contacts",
        name: "Admin - Contacts",
        icon: <FontAwesomeIcon icon={faUserGroup} />,
        type: "admin"
    },
    {
        id: "/admin/contact-details",
        name: "Admin - Contact Details",
        icon: <FontAwesomeIcon icon={faUsersViewfinder} />,
        type: "admin"
    },

    {
        id: "/logout",
        name: "Logout",
        icon: <FontAwesomeIcon icon={faRightFromBracket} />,
        type: "signed-in"
    },

    {
        id: "/example-dashboard",
        name: "Example Dashboard",
        icon: <FontAwesomeIcon icon={faChartColumn} />,
        type: "signed-out"
    },
    {
        id: "/sign-in",
        name: "Sign In",
        icon: <FontAwesomeIcon icon={faRightToBracket} />,
        type: "signed-out"
    },
    {
        id: "/sign-up",
        name: "Sign Up",
        icon: <FontAwesomeIcon icon={faUserPlus} />,
        type: "signed-out"
    },
    {
        id: "/forgot-password",
        name: "Forgot Password",
        icon: <FontAwesomeIcon icon={faKey} />,
        type: "signed-out"
    },

    {
        id: "/privacy-policy",
        name: "Privacy Policy",
        icon: <FontAwesomeIcon icon={faSection} />,
        type: "legal"
    },
    {
        id: "/terms-and-conditions",
        name: "Terms and Conditions",
        icon: <FontAwesomeIcon icon={faFileContract} />,
        type: "legal"
    },
    {
        id: "/imprint",
        name: "Imprint",
        icon: <FontAwesomeIcon icon={faIdCard} />,
        type: "legal"
    }, {
        id: "/cookie-settings",
        name: "Cookie Settings",
        icon: <FontAwesomeIcon icon={faCookie} />,
        type: "cookie"
    },
];

export const COOKIE_CONSENT_SETTINGS: CookieConsentConfig = {
    guiOptions: {
        consentModal: {
            layout: "box",
            position: "bottom left",
            equalWeightButtons: true,
            flipButtons: false
        },
        preferencesModal: {
            layout: "box",
            position: "right",
            equalWeightButtons: true,
            flipButtons: false
        }
    },
    categories: {
        necessary: {
            enabled: true,
            readOnly: true
        },
        functionality: {},
        analytics: {}
    },
    language: {
        default: "en",
        autoDetect: "browser",
        translations: {
            en: {
                consentModal: {
                    title: "Cookie Consent",
                    description: "We use cookies and other tracking technologies to improve your browsing experience on our website, to show you personalized content, to analyze our website traffic, and to understand where our visitors are coming from.",
                    acceptAllBtn: "Accept all",
                    acceptNecessaryBtn: "Reject all",
                    showPreferencesBtn: "Manage preferences",
                    footer: "<a href=\"/privacy-policy\">Privacy Policy</a>\n<a href=\"/terms-and-conditions\">Terms and conditions</a>"
                },
                preferencesModal: {
                    title: "Consent Preferences Center",
                    closeIconLabel: "Close modal",
                    acceptAllBtn: "Accept all",
                    acceptNecessaryBtn: "Reject all",
                    savePreferencesBtn: "Save preferences",
                    serviceCounterLabel: "Service|Services",
                    sections: [
                        {
                            title: "Cookie Usage",
                            description: "Cookies are very small text files that are stored on your computer when you visit a website. We use cookies for a variety of purposes and to enhance your online experience on our website (for example, to remember your account login details).\n\nYou can change your preferences and decline certain types of cookies to be stored on your computer while browsing our website. You can also remove any cookies already stored on your computer, but keep in mind that deleting cookies may prevent you from using parts of our website."
                        },
                        {
                            title: "Strictly Necessary Cookies <span class=\"pm__badge\">Always Enabled</span>",
                            description: "These cookies are essential to provide you with services available through our website and to enable you to use certain features of our website.\n\nWithout these cookies, we cannot provide you certain services on our website.",
                            linkedCategory: "necessary"
                        },
                        {
                            title: "Functionality Cookies",
                            description: "These cookies are used to provide you with a more personalized experience on our website and to remember choices you make when you use our website.\n\nFor example, we may use functionality cookies to remember your language preferences or remember your login details.",
                            linkedCategory: "functionality"
                        },
                        {
                            title: "Analytics Cookies",
                            description: "These cookies are used to collect information to analyze the traffic to our website and how visitors are using our website.\n\nFor example, these cookies may track things such as how long you spend on the website or the pages you visit, which helps us to understand how we can improve our website for you.\n\nThe information collected through these tracking and performance cookies do not identify any individual visitor.",
                            linkedCategory: "analytics"
                        },
                        {
                            title: "More information",
                            description: "For any query in relation to our policy on cookies and your choices, please <a class=\"cc__link\" href=\"/imprint\">contact us</a>."
                        }
                    ]
                }
            }
        }
    },
    disablePageInteraction: false
};

export const PLAN_DETAILS = {
    trial: {
        price: {
            eur: 0,
            chf: 0,
            usd: 0
        },
        qp: 100,
        wi: 75
    },
    small: {
        price: {
            eur: 25,
            chf: 25,
            usd: 25
        },
        qp: 750,
        wi: 500
    },
    medium: {
        price: {
            eur: 175,
            chf: 175,
            usd: 175
        },
        qp: 7500,
        wi: 5000
    },
    large: {
        price: {
            eur: 1050,
            chf: 1050,
            usd: 1050
        },
        qp: 75000,
        wi: 50000
    }
}

export const TYPE_NAMES: { 
    id: "workitem" | "chatbot" | "linked-item";
    name: "Workitem Analysis" | "Chatbot" | "Linked Item";
}[] = [{ id: "workitem", name: "Workitem Analysis" }, { id: "chatbot", name: "Chatbot" }, { id: "linked-item", name: "Linked Item" }]

export const SIDEBAR_WIDTH = 280;

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:7171";

export const GA_TRACKING_ID = "G-GD07MPKDN0";