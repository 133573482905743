import { Suspense, lazy, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";

import { Stack, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import { COOKIE_CONSENT_SETTINGS, DARK_THEME, GA_TRACKING_ID, LIGHT_THEME } from "./Constants";
import SideBar from "./components/helpers/SideBar";

import { DialogProvider } from "./contexts/DialogContext";
import { UserProvider } from "./contexts/UserContext";

import LoaderView from "./views/LoaderView";
import { ICookieSettings } from "./react-app-env";

import ReactGA from 'react-ga4';

const InfoView = lazy(() => import("./views/InfoView"));

const IndexViewsAuthenticated = lazy(() => import("./views/authenticated/IndexViewsAuthenticated"));
const AccountView = lazy(() => import("./views/authenticated/AccountView"));
const DownloadView = lazy(() => import("./views/authenticated/DownloadView"));
const LogoutView = lazy(() => import("./views/authenticated/LogoutView"));

const IndexViewsUnauthenticated = lazy(() => import("./views/unauthenticated/IndexViewsUnauthenticated"));
const SignUpView = lazy(() => import("./views/unauthenticated/SignUpView"));
const SignInView = lazy(() => import("./views/unauthenticated/SignInView"));
const ForgotPasswordView = lazy(() => import("./views/unauthenticated/ForgotPasswordView"));

const ContactsView = lazy(() => import("./views/admin/ContactsView"));
const ContactDetailsView = lazy(() => import("./views/admin/ContactDetailsView"));

const ImprintView = lazy(() => import("./views/legal/ImprintView"));
const PrivacyView = lazy(() => import("./views/legal/PrivacyView"));
const TermsView = lazy(() => import("./views/legal/TermsView"));

const App = () => {
  const [cookies, setCookies] = useState<ICookieSettings>({
    functionality: false,
    analytics: false,

    onChange: undefined,
  });

  useEffect(() => {
    CookieConsent.run({
      ...COOKIE_CONSENT_SETTINGS,
      onConsent: () => {
        setCookies((cookies) => ({
          ...cookies,
          functionality: CookieConsent.acceptedCategory("functionality"),
          analytics: CookieConsent.acceptedCategory("analytics"),
        }));
      },
      onChange: () => {
        setCookies((cookies) => ({
          ...cookies,
          functionality: CookieConsent.acceptedCategory("functionality"),
          analytics: CookieConsent.acceptedCategory("analytics"),
        }));
      }
    });
    document.documentElement.classList.add('cc--darkmode');
  }, []);

  useEffect(() => {
    if (cookies.analytics) {
      console.log("Enabling Google Analytics");
  
      ReactGA.initialize(GA_TRACKING_ID, {
        gaOptions: {
          anonymizeIp: true,
          allowAdFeatures: false,
          allowAdPersonalizationSignals: false
        }
      });
    }
  }, [cookies])

  const content = <>
    <DialogProvider cookies={cookies} setCookies={setCookies}>
      <Stack sx={{
        flexDirection: {
          xs: "column",
          lg: "row"
        }
      }}>
        <ThemeProvider theme={DARK_THEME}>
          <SideBar />
        </ThemeProvider>

        <Suspense fallback={<LoaderView progress={true} />}>
          <Routes>
            <Route index path="/" element={<InfoView />} />

            <Route path="/example-dashboard" element={<IndexViewsUnauthenticated />} />
            <Route path="/sign-up" element={<SignUpView />} />
            <Route path="/sign-in" element={<SignInView />} />
            <Route path="/forgot-password" element={<ForgotPasswordView />} />

            <Route path="/dashboard" element={<IndexViewsAuthenticated />} />
            <Route path="/account" element={<AccountView />} />
            <Route path="/downloads" element={<DownloadView />} />
            <Route path="/logout" element={<LogoutView />} />

            <Route path="/admin/contacts" element={<ContactsView />} />
            <Route path="/admin/contact-details" element={<ContactDetailsView />} />

            <Route path="/privacy-policy" element={<PrivacyView />} />
            <Route path="/terms-and-conditions" element={<TermsView />} />
            <Route path="/imprint" element={<ImprintView />} />
          </Routes>
        </Suspense>
      </Stack>
    </DialogProvider>
  </>;

  return (
    <div className="App">
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={LIGHT_THEME}>
          <UserProvider cookies={cookies}>
            {cookies.functionality ?
              content
              :
              content
            }
          </UserProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </div>
  )
}

export default App;
